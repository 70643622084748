<template>
    <div class="modal profile_modal share_playbook" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="m-0 pb-4">Assigned User</h1>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body">
                <div class="tab_wpr mt-3">
                    <div class="result_wpr">
                        <div class="actions">
                            <ul>
                                <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                                    {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                    <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="form-respondents-per-page-filter" />
                                </li>
                                <li><input type="text" class="p-0" @input="isTyping = true" v-model.trim="params.search" placeholder="Search"/>
                                    <i class="fas fa-search"></i>
                                </li>
                                <li class="list_info">
                                    {{ formRespondents.from ? formRespondents.from : 0 }} - {{ formRespondents.to ? formRespondents.to : 0 }} of <span>{{ formRespondents.total ? formRespondents.total : 0 }}</span>
                                </li>
                            </ul>
                        </div>
                        <div v-if="loader"><line-loader /></div>
                        <table class="standard">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Username</th>
                                    <th>Date</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody v-if="formRespondents.data && formRespondents.data.length">
                                <tr v-for="(respondent, r) in formRespondents.data" :key="r">
                                    <td></td>
                                    <td>
                                        <div class="user_wpr">
                                            <h4>{{ respondent.contact.name }}</h4>
                                            <span class="text-danger ml-2" v-if="respondent.contact.isDelete == 1">Deleted</span>
                                        </div>
                                    </td>
                                    <td><div class="email_wpr">{{ respondent.contact.email }}</div></td>
                                    <td>{{ moment(respondent.date_created).format('ll') }}</td>
                                    <td>
                                        <ul class="action_list">
                                            <li @click="handleViewFormResponse(respondent)"><i class="fas fa-search fa-fw"></i></li>
                                            <li @click="handleDeleteSubmittedSurvey(respondent)"><i class="fas fa-trash-alt"></i></li>
                                            <li @click="toggleLaunchPad(respondent.contact)"><img src="@/assets/images/launchpad.svg" width="15" height="15"></li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="3" class="px-4">No Records Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="pagination pb-4 mt-4" v-if="formRespondents.data && formRespondents.data.length">
                        <pagination v-model="params.page" :pages="formRespondents.last_page" @update:modelValue="handlePagination" />
                    </div>

                    <form-detail v-model="showFormDetails" :form-details="formDetails" :respondent="selectedRespondent" />
                </div>
            </div>
            <div class="modal_footer">
                <button class="btn cancel_btn" @click="closeModal()">Cancel</button>
            </div>
        </div>
        <launch-pad v-model="lauchPad" :contact="selectedContact" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import {mapState, mapActions} from 'vuex';

    const PageFilter = defineAsyncComponent(() => import('@/components/PageFilter'))
    const LineLoader = defineAsyncComponent(() => import('@/components/LineLoader'))
    const FormDetail = defineAsyncComponent(() => import('@/pages/form/components/FormDetail'))
    const LaunchPad = defineAsyncComponent(() => import('@/pages/contact/components/LaunchPad'))

    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        data () {
            return {
                params: {
                    per_page: 5,
                    page: 1,
                    search: '',
                    survey_id: '',
                },
                moment,
                isTyping: false,
                showFormDetails: false,
                formDetails: {
                    respondent_info: {
                        name: '',
                        email: '',
                        number: '',
                        full_address: '',
                        birthdate: '',
                    },
                    survey_info: {
                        survey_name: ''
                    },
                    custom_fields: [],
                    custom_fields_answers: {},
                    contact: {},
                },
                selectedRespondent: {},
                selectedContact: {},
                lauchPad: false,
            }
        },

        props: {
            modelValue: Boolean,
            activeForm: Object,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (val) {
                const vm = this;

                if (val) {
                    document.body.classList.add('modal-open');

                    vm.params = {
                        per_page: 5,
                        page: 1,
                        search: '',
                        survey_id: vm.activeForm.id,
                    };
                    vm.showFormDetails = false;

                    vm.getRespondent(vm.params);
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getRespondent(vm.params);
            },

            'params.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500)
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length == 0 || vm.params.search.length >= 2) {
                            vm.params.page = 1;

                            vm.getRespondent(vm.params);
                        }
                    }
                }
            },
        },

        components: {
            PageFilter,
            LineLoader,
            FormDetail,
            LaunchPad,
        },

        computed: mapState({
            loader: state => state.formModule.formComponentLoader,
            formRespondents: state => state.formModule.formRespondents,
            user: state => state.authModule.user,
        }),

        methods: {
            ...mapActions({
                getRespondent: 'formModule/getRespondent',
                deleteSubmittedForm: 'formModule/deleteSubmittedForm',
                viewFormResponse: 'formModule/viewFormResponse',
                downloadFormResponse: 'formModule/downloadFormResponse',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            togglePerPageFilter () {
                const vm = this;

                const filter = vm.$refs['form-respondents-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['form-respondents-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getRespondent(vm.params);
            },

            handleDeleteSubmittedSurvey (form) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are you sure?', 'You will not be able to recover these survey.', 'Yes', false);

                Swal.fire(option).then((result) => {
                    if (result.isConfirmed) {
                        const params = {
                            form_user_rel_id: form.id,
                            user_id: form.user_id,
                            survey_id: form.survey_id,
                        };

                        vm.deleteSubmittedForm(params).then((result) => {
                            if (result) {
                                vm.params.page = 1;
                                vm.getRespondent(vm.params);
                            }
                        });
                    }
                });
            },

            handleViewFormResponse (form) {
                const vm = this;

                const formData = {
                    contact_id: form.user_id,
                    survey_id: form.survey_id,
                    respondent_id: form.respondent_id,
                }

                vm.selectedRespondent = form;

                vm.viewFormResponse(formData).then((result) => {
                    vm.formDetails = {
                        respondent_info: {
                            name: result.respondent_info.name,
                            email: result.respondent_info.email,
                            number: result.respondent_info.number,
                            full_address: result.respondent_info.full_address,
                            birthdate: result.respondent_info.birthdate,
                        },
                        survey_info: result.survey_info,
                        survey_questions: result.survey_questions,
                        signatures: result.signatures,
                        custom_fields: result.custom_fields,
                        custom_fields_answers: result.custom_fields_answers,
                        contact: result.contact,
                    };

                    vm.showFormDetails  = true;
                });
            },

            toggleLaunchPad (contact) {
                const vm = this;

                vm.selectedContact = contact;
                vm.lauchPad = true;
            },
        }
    }
</script>

<style scoped>
    .share_playbook .modal_container {
        height: 640px;
    }

    .share_playbook .modal_header {
        padding: 20px 30px 0;
    }

    .share_playbook .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .share_playbook .tab_row {
        padding: 20px 0 0 0;
        display: flex;
    }

    .share_playbook .tab_row li {
        padding: 8px 20px;
        position: relative;
        cursor: pointer;
    }

    .share_playbook .tab_row li.active {
        background: #eaeaea;
        border-radius: 5px 5px 0 0;
    }

    .share_playbook .tab_wpr {
        width: 100%;
    }

    .share_playbook .result_wpr {
        width: 100%;
    }

    .action_list {
        display: flex;
        flex-wrap: wrap;
    }

    .action_list li {
        margin-right: 5px;
        cursor: pointer;
    }

    .action_list li i.fa-envelope {
        color: #f2a31d;
    }

    .action_list li i.fa-reply {
        color: #2f7eed;
    }

    .action_list li i.fa-trash-alt {
        color: #eb1414;
    }

    .info_wpr {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 20px ;
        padding: 20px 15px;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        box-shadow: 0 0 10px rgba(0,0,0,0.07);
        position: relative;
    }

    .info_wpr:before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        height: 4px;
        background: var(--editbar-color, #f2a31d);
        border-radius: 6px 6px 0 0;
    }

    .info_wpr .border_item {
        flex: 1 0 50%;
        padding: 5px;
    }

    .info_wpr .border_item h5 {
        font-size: 12px !important;
    }

    .info_wpr .border_item p {
        border: 1px solid #d9d9d9;
        padding: 6px 10px;
        border-radius: 5px;
        min-height: 28px;
    }

    .answer_item {
        padding: 5px;
        font-size: 10px;
        line-height: 18px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        margin: 7px 0 15px;
        min-height: 30px;
    }

    .form_details {
        padding: 25px 15px 15px 15px;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        box-shadow: 0 0 10px rgba(0,0,0,0.07);
        position: relative;
    }

    .form_details:before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        height: 4px;
        background: var(--editbar-color, #f2a31d);
        border-radius: 6px 6px 0 0;
    }

    .download_btn {
        font-size: 13px;
        cursor: pointer;
        position: absolute;
        right: 45px;
    }

    .privacy_info {
        display: flex;
        padding: 20px 25px;
        border: 1px solid #bdeafa;
        background: rgba(149, 215, 239, 0.2);
        border-radius: 5px;
        align-items: flex-start;
        margin: 30px 0 20px 0;
    }

    .privacy_info i {
        font-size: 34px;
        color: #f2a31d;
    }

    .privacy_info p {
        font-size: 13px;
        line-height: 18px;
        margin-left: 12px;
    }

    .signature_fld {
        display: flex;
        justify-content: space-between;
        font-family: Caveat,cursive;
        margin: 0 0 20px;
        padding: 10px 0;
        font-size: 20px;
        line-height: 25px;
        border-bottom: 1px solid #d9d9d9;
    }

    .form_details.legal_info label p {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #5a5a5a !important;
    }

    .privacy_info p {
        font-size: 13px;
        line-height: 18px;
        margin-left: 12px !important;
    }
    :deep(.edit_modal){
        border-radius: 10px;
    }
</style>
